import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class Imprint extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title={`LACK | Impressum`}
          keywords={[`techno`, `label`, `records`]}
        />
        <div className="page data">
          <h1>Impressum</h1>
          <p>Responsible for content according to § 5 TMG</p>
          <br />

          <p>
            LACKRec. c/o <br />
            Claudio Banti <br />
            Hermannstraße 161 <br />
            12051 Berlin <br />
            <br />
            +49 157 31661319
            <br />
            info@lackrec.com
            <br />
            Font: www.abcdinamo.com
          </p>
        </div>
      </Layout>
    )
  }
}

export default Imprint
